/**
 * @name: 会员管理-会员等级管理接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 会员管理-会员等级管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { ILevel } from "./types";


export const levelQueryApi = () => get<ILevel[]>("/admin/user/grade/getList")
