
/**
 * @name: 会员管理-会员等级管理
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 会员管理-会员等级管理
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  levelQueryApi
} from '@/apis/member/level'
import {
  ILevel
} from '@/apis/member/level/types'
import type { ICrudOption } from "@/types/m-ui-crud"

@Component({})
export default class memberLevel extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ILevel[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: '会员等级',
        prop: 'gradeName',
        align: 'center'
      },
      {
        label: '级别贡献值门槛',
        prop: 'gradeStartLine',
        align: 'center'
      },
      {
        label: '全网贡献值分成比例',
        prop: 'divideIntoRatio',
        align: 'center',
        slot: true
      }
    ]
  }

  getList () {
    this.tableLoading = true

    levelQueryApi().then(e => {
      this.tableData = e.filter(item => item.gradeName !== 'VIP0')
      this.tableLoading = false
    })
  }

  created () {
    this.getList()
  }
}
